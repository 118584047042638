import { Button, Typography } from 'amphitheatre';
import { graphql, Link, StaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FaLinux } from 'react-icons/fa';
import { ImAppleinc, ImWindows } from 'react-icons/im';
import Container from 'src/Components/ContentContainer';
import SEO from 'src/Components/SEO';
import Layout from 'src/Layout';
import * as Hero from 'src/page-styles/hero.styled';
import styled from 'styled-components';

const { Header, Paragraph } = Typography;

interface Props {
  data: {
    pigi: {
      frontmatter: {
        linux: string;
        mac: string;
        win32: string;
        win64: string;
      };
    };
    other: {
      edges: Array<{
        node: {
          frontmatter: {
            title: string;
            description: string;
            link: string;
          };
        };
      }>;
    };
  };
}

const OtherVersions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  a button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Tutorial = styled.div`
  transition: all 0.35s ease;
  &:hover {
    cursor: pointer;

    transform: translateY(-16px);
  }
`;

const Tutorials = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const TutorialTitle = styled.div`
  background: ${({ theme }) => theme.primary500};
  color: black;
  font-family: ${({ theme }) => theme.fontHeader};
  padding: 0.5rem 1rem;
  text-align: center;
`;

const Resources: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { pigi, other } = data;
  const { frontmatter } = pigi;
  const { edges: resources } = other;

  const options = ['P.I.G.I. Download'];
  const downloads = [
    <React.Fragment key={0}>
      <Header level={3}>P.I.G.I. Download</Header>
      <OtherVersions>
        <a href={frontmatter.win32} style={{ textDecoration: 'none' }}>
          <Button>
            <ImWindows /> Windows 32-bit
          </Button>
        </a>
        <a href={frontmatter.win64} style={{ textDecoration: 'none' }}>
          <Button>
            <ImWindows /> Windows 64-bit
          </Button>
        </a>
        <a href={frontmatter.mac} style={{ textDecoration: 'none' }}>
          <Button>
            <ImAppleinc /> Mac OS
          </Button>
        </a>
        <a href={frontmatter.linux} style={{ textDecoration: 'none' }}>
          <Button>
            <FaLinux /> Linux
          </Button>
        </a>
      </OtherVersions>
    </React.Fragment>,
  ];

  resources.forEach(({ node }, index) => {
    const { frontmatter } = node;
    options.push(frontmatter.title);

    downloads.push(
      <React.Fragment key={index + 1}>
        <Header level={3} style={{ textTransform: 'uppercase' }}>
          {frontmatter.title}
        </Header>
        <Paragraph>{frontmatter.description}</Paragraph>
        <Paragraph>
          <a
            href={frontmatter.link}
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Link to {frontmatter.title}</Button>
          </a>
        </Paragraph>
      </React.Fragment>,
    );
  });

  return (
    <>
      <SEO
        title="Resources | Saber Astronautics"
        description="Downloads and tutorials from Saber Astronautics to get you started and bring your ideas to life."
        url="https://www.saberastro.com/tutorials"
      />
      <Layout>
        <Hero.Wrapper className="resources" style={{ minHeight: '100vh' }}>
          <Hero.Cover />
          <Hero.Cover />
          <StaticImage
            className="hero-image"
            src="../images/resources.jpg"
            alt="Downloads nice image"
          />
          <Hero.Text>
            <Container style={{ padding: '0 0 1rem 0' }}>
              <Header>P.I.G.I. Tutorials</Header>
              <Tutorials>
                <Link
                  to="/tutorials/tutorial1"
                  style={{ textDecoration: 'none' }}
                >
                  <Tutorial>
                    <StaticImage
                      src="../images/resources/tut1.jpg"
                      alt="Tutorial 1"
                    />
                    <TutorialTitle>1: Basics</TutorialTitle>
                  </Tutorial>
                </Link>
                <Link
                  to="/tutorials/tutorial2"
                  style={{ textDecoration: 'none' }}
                >
                  <Tutorial>
                    <StaticImage
                      src="../images/resources/tut2.jpg"
                      alt="Tutorial 2"
                    />
                    <TutorialTitle>2: Satellites</TutorialTitle>
                  </Tutorial>
                </Link>
                <Link
                  to="/tutorials/tutorial3"
                  style={{ textDecoration: 'none' }}
                >
                  <Tutorial>
                    <StaticImage
                      src="../images/resources/tut3.jpg"
                      alt="Tutorial 3"
                    />
                    <TutorialTitle>3: Ground stations</TutorialTitle>
                  </Tutorial>
                </Link>
                <Link
                  to="/tutorials/tutorial4"
                  style={{ textDecoration: 'none' }}
                >
                  <Tutorial>
                    <StaticImage
                      src="../images/resources/tut4.jpg"
                      alt="Tutorial 4"
                    />
                    <TutorialTitle>4: Save a scene</TutorialTitle>
                  </Tutorial>
                </Link>
                <Link
                  to="/tutorials/tutorial5"
                  style={{ textDecoration: 'none' }}
                >
                  <Tutorial>
                    <StaticImage
                      src="../images/resources/tut5.jpg"
                      alt="Tutorial 5"
                    />
                    <TutorialTitle>5: Overpass Data</TutorialTitle>
                  </Tutorial>
                </Link>
              </Tutorials>
            </Container>
          </Hero.Text>
        </Hero.Wrapper>
      </Layout>
    </>
  );
};

export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query {
        pigi: markdownRemark(frontmatter: { type: { eq: "pigi_download" } }) {
          frontmatter {
            win32
            win64
            mac
          }
        }
        other: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "resources" } } }
        ) {
          edges {
            node {
              frontmatter {
                description
                title
                link
              }
            }
          }
        }
      }
    `}
    render={(data) => <Resources data={data} />}
  />
);
